import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "main",
        component: () => import("./pages/PageMain")
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./pages/PageLogin")
    },
    {
        path: "/uploader/raw_template",
        name: "rawTemplateUploader",
        component: () => import("./pages/PageRawTemplateUploader")
    },
    {
        path: "/uploader/meta_data/product",
        name: "productMetaDataUploader",
        component: () => import("./pages/PageProductMetaDataUploader")
    },
    {
        path: "/uploader/meta_data/general/customization_label_mapping",
        name: "generalMetaDataCustomizationLabelMappingUploader",
        component: () => import("./pages/PageGeneralMetaDataCustomizationLabelMappingUploader")
    },
    {
        path: "/templates",
        name: "templates",
        component: () => import("./pages/PageRawTemplates")
    },
    {
        path: "/workstations",
        name: "workstations",
        component: () => import("./pages/PageAdminWorkStations")
    },
    {
        path: "/automation",
        name: "automation",
        component: () => import("./pages/PageAutomation")
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("./pages/PageSettings")
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("./pages/PageLogout")
    },
    {
        path: "/workstation",
        name: "workstation",
        component: () => import("./pages/PageWorkStation")
    },
    {
        path: "/workbench",
        name: "workbench",
        component: () => import("./pages/PageWorkBench")
    },
    {
        path: "/test",
        name: "test",
        component: () => import("./pages/PageTest")
    }


]

const router = createRouter({
        history: createWebHistory(),
        routes: routes,
    }
)

export default router;