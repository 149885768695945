import {createApp} from 'vue'
import {createPinia} from 'pinia'

import App from './App.vue'


import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/saga-blue/theme.css' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css' //icons
import 'primeflex/primeflex.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

import ToastService from 'primevue/toastservice';

import router from '@/routes';
import piniaPersist from 'pinia-plugin-persist'
import VueHighlightJS from 'vue3-highlightjs'
import ConfirmationService from 'primevue/confirmationservice';
import visibility from 'vue-visibility-change';

import Tooltip from 'primevue/tooltip';


const pinia = createPinia()
pinia.use(piniaPersist)

const app = createApp(App,);

app.use(pinia)
app.use(router);
app.use(PrimeVue);
app.use(VueAxios, axios)
app.use(ToastService);
app.use(VueHighlightJS);
app.use(ConfirmationService);
app.use(visibility);

app.directive('tooltip', Tooltip);


app.mount('#app')


