<template>
  <Toast/>
  <ConfirmDialog></ConfirmDialog>
  <div class="grid">
    <div class="col col-12" v-if="theGlobalStore.authToken">
      <TabMenu :model="pages"/>
    </div>
    <div class="col col-12">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import {useTheGlobalStore} from '@/store';
import router from '@/routes';
import Toast from 'primevue/toast';
import TabMenu from 'primevue/tabmenu';
import {computed, onMounted} from "vue";
import ConfirmDialog from 'primevue/confirmdialog';
import {useToast} from "primevue/usetoast";

const theGlobalStore = useTheGlobalStore()

if (!theGlobalStore.authToken) {
  router.push('/login')
}

const runtimeEnvironment = process.env.VUE_APP_RUNTIME_ENVIRONMENT || 'DEVEL'
const apiUrl = {
  DEVEL: process.env.VUE_APP_DEVEL_API_URL,
  STAGE: process.env.VUE_APP_STAGE_API_URL,
  PROD: process.env.VUE_APP_PROD_API_URL
}[runtimeEnvironment]

if (runtimeEnvironment === 'PROD') {
  theGlobalStore.setIsProduction(true)
} else {
  theGlobalStore.setIsProduction(false)
}

theGlobalStore.setApiUrl(apiUrl)

const uploadsPage = computed(() => {

  if (theGlobalStore.pageUploadsActiveTabIndex === null | theGlobalStore.pageUploadsActiveTabIndex === 0) {
    return '/uploader/raw_template'
  } else if (theGlobalStore.pageUploadsActiveTabIndex === 1) {
    return '/uploader/meta_data/product'
  } else if (theGlobalStore.pageUploadsActiveTabIndex === 2) {
    return '/uploader/meta_data/general/customization_label_mapping'
  } else {
    return '/error'
  }
})


const pages = computed(() => {
  if (theGlobalStore.isAdmin) {
    return [
      {label: 'Main', icon: 'pi pi-fw pi-home', to: '/'},
      {label: 'Work Stations', icon: 'pi pi-fw pi-sitemap', to: '/workstations'},
      {label: 'Automation', icon: 'pi pi-fw pi-apple', to: '/automation'},
      {label: 'Templates', icon: 'pi pi-fw pi-table', to: '/templates'},
      {label: 'Uploads', icon: 'pi pi-fw pi-upload', to: uploadsPage},
      {label: 'Settings', icon: 'pi pi-fw pi-list', to: '/settings'},
      {label: 'Work Bench', icon: 'pi pi-fw pi-pencil', to: '/workbench'},
      {label: 'Log Out', icon: 'pi pi-fw pi-sign-out', to: '/logout'}
    ]
  } else {
    return [
      {label: 'WS - ' + (theGlobalStore.workStationId + 1).toString(), icon: 'pi pi-fw pi-home', to: '/workstation'},
      {label: 'Log Out', icon: 'pi pi-fw pi-sign-out', to: '/logout'}
    ]
  }
})

onMounted(() => {
  theGlobalStore.setToast(useToast());
})


</script>
