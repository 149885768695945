import {defineStore} from 'pinia';
import {ref} from 'vue';

export const useTheGlobalStore = defineStore('theGlobalStore', () => {
        const authToken = ref(null)
        const filesRegistered = ref({})
        const packagesRegistered = ref({})


        const pageUploadsActiveTabIndex = ref(0)
        const workStationsActiveTabIndex = ref(0)
        const isAdmin = ref(false)
        const workStationId = ref(-1)
        const isAnyActionContinue = ref(false)
        const assignedPackagesCurrent = ref(null)
        const assignedPackagesQueue = ref([])

        const apiUrl = ref(null)
        const isProduction = ref(false)

        const toast = ref(null)

        const finishedPackages = ref({})
        const workStations = ref({})
        const workers = ref({})
        const system = ref({})

        const genericVariables = ref({})
        const genericStats = ref([])

        const testData = ref(null);

        const isBrowserTabActive = ref(true);


        function getFinishedPackages(workStationId, forUtcTs) {
            if (finishedPackages.value[workStationId] === undefined || finishedPackages.value[workStationId] === null) {
                return null
            }

            let forUtcTsStr = forUtcTs.toISOString();
            forUtcTsStr = forUtcTsStr.substring(0, forUtcTsStr.length - 1);

            if (finishedPackages.value[workStationId][forUtcTsStr] === undefined || finishedPackages.value[workStationId][forUtcTsStr] === null) {
                return null
            } else {
                return finishedPackages.value[workStationId][forUtcTsStr]
            }
        }

        function addFinishedPackages(workStationId, forUtcTs, packages) {
            if (finishedPackages.value[workStationId] === undefined || finishedPackages.value[workStationId] === null) {
                finishedPackages.value[workStationId] = {}
            }
            let forUtcTsStr = forUtcTs.toISOString();
            forUtcTsStr = forUtcTsStr.substring(0, forUtcTsStr.length - 1);
            finishedPackages.value[workStationId][forUtcTsStr] = packages
        }

        function setApiUrl(value) {
            apiUrl.value = value;
        }

        function setIsProduction(value) {
            isProduction.value = value;
        }

        function setIsAnyActionContinue(value) {
            isAnyActionContinue.value = value
        }


        function setWorkStationId(value) {
            workStationId.value = value
        }

        function setAuthToken(value) {
            authToken.value = value
        }


        function setIsAdmin(value) {
            isAdmin.value = value
        }

        function addOrUpdate2FilesRegistered(key, value) {
            filesRegistered.value[key] = value
        }

        function removeFromFilesRegistered(key) {
            delete filesRegistered.value[key]
        }

        function clearFilesRegistered() {
            filesRegistered.value = {}
        }

        function addOrUpdate2PackagesRegistered(key, value) {
            packagesRegistered.value[key] = value
        }

        function removeFromPackagesRegistered(key) {
            delete packagesRegistered.value[key]
        }

        function clearPackagesRegistered() {
            packagesRegistered.value = {}
        }

        function setToast(value) {
            toast.value = value
        }

        function setAssignedPackagesCurrent(value) {
            assignedPackagesCurrent.value = value
        }

        function setAssignedPackagesQueue(value) {
            assignedPackagesQueue.value = value
        }


        function setGenericVariable(key, value) {
            genericVariables.value[key] = value;
        }

        function setGenericVariables(value) {
            genericVariables.value = value;
        }

        function setGenericStats(value) {
            genericStats.value = value;
        }

        function setTestData(value) {
            testData.value = value;
        }

        function setIsBrowserTabActive(value) {
            isBrowserTabActive.value = value;
        }

        function setWorkStations(value) {
            workStations.value = value;
        }

        function setWorkers(value) {
            workers.value = value;
        }

        function setSystem(value) {
            system.value = value;
        }

        function resetAll() {
            authToken.value = null;
            filesRegistered.value = {};
            packagesRegistered.value = {};
            pageUploadsActiveTabIndex.value = 0;
            workStationsActiveTabIndex.value = 0;
            isAdmin.value = false;
            workStationId.value = -1;
            isAnyActionContinue.value = false;
            assignedPackagesCurrent.value = null;
            assignedPackagesQueue.value = [];
            apiUrl.value = null;
            isProduction.value = false;
            toast.value = null;
            finishedPackages.value = {};
            workStations.value = {};
            workers.value = {};
            system.value = {};
            genericVariables.value = {};
            genericStats.value = [];
            testData.value = null;
            isBrowserTabActive.value = true;
        }


        return {
            resetAll,
            authToken, setAuthToken,
            filesRegistered, addOrUpdate2FilesRegistered, removeFromFilesRegistered, clearFilesRegistered,
            pageUploadsActiveTabIndex, workStationsActiveTabIndex,
            isAdmin, setIsAdmin,
            workStationId, setWorkStationId,
            packagesRegistered, addOrUpdate2PackagesRegistered, removeFromPackagesRegistered, clearPackagesRegistered,
            finishedPackages, getFinishedPackages, addFinishedPackages,
            isAnyActionContinue, setIsAnyActionContinue,
            apiUrl, setApiUrl,
            isProduction, setIsProduction,
            toast, setToast,
            assignedPackagesCurrent, setAssignedPackagesCurrent,
            assignedPackagesQueue, setAssignedPackagesQueue,
            genericVariables, setGenericVariable, setGenericVariables,
            genericStats, setGenericStats,
            testData, setTestData,
            isBrowserTabActive, setIsBrowserTabActive,
            workStations, setWorkStations,
            workers, setWorkers,
            system, setSystem
        }
    },
    {
        persist: {
            enabled: true,
            strategies: [
                {
                    key: 'theGlobalStore',
                    storage: localStorage,
                },
            ],
        }
    }
)
